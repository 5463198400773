const menuElement = document.querySelector('.js-menu');
const headerElement = document.querySelector('.js-header');
const body = document.querySelector('body');

const observerCallback = ([entry]) => {
  const isStuck = entry.intersectionRatio < 1;

  menuElement.classList.toggle('is-stuck', isStuck);
  headerElement.classList.toggle('is-menu-stuck', isStuck);
  body.classList.toggle('has-stuck-menu', isStuck);

  if (headerElement.classList.contains('has-open-search')) {
    headerElement.classList.remove('has-open-search', isStuck);
    body.classList.remove('has-open-search', isStuck);
  }
};

const observerOptions = { threshold: [1] };

const menuObserver = new IntersectionObserver(observerCallback, observerOptions);

menuObserver.observe(menuElement);
