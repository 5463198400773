
var $basedriverNewsletterForm,
    $body,
    $header,
    $search,
    $searchOpen,
    $searchClose,
    $submenuBtn,
    $submenu,
    $toggleFAQ,
    $toggleMenu;


$(document).ready(function($) {

    console.log('%cLinku Novio',"font-size: 2em; font-family: sans-serif; color: white; background-color:#18aaff; padding:4px 8px; border-radius: 4px");

    $basedriverNewsletterForm   = $('.js-basedriver-newsletter-form');
    $body                       = $('body');
    $header                     = $('.js-header');
    $search                     = $('.js-search');
    $searchToggle               = $('.js-search-toggle');
    $searchOpen                 = $('.js-open-search');
    $searchClose                = $('.js-close-search');
    $submenOpen                 = $('.js-submenu-open');
    $submenuClose               = $('.js-submenu-close');
    $submenu                    = $('.js-submenu');
    $toggleFAQ                  = $('.js-toggle-faq');
    $toggleMenu                 = $('.js-toggle-menu');
    $menu                       = $('.js-menu');

    $(window).on('scroll', function () {
        var scrollingHeaderClass = 's-header--shadow';

        if ($(this).scrollTop() >= 75 && !$header.hasClass(scrollingHeaderClass)) {
            $header.addClass(scrollingHeaderClass);
        } else if($(this).scrollTop() < 75 && $header.hasClass(scrollingHeaderClass)) {
            $header.removeClass(scrollingHeaderClass);
        }
    });

    $toggleMenu.on('click', function (e) {
        e.preventDefault(); // Prevents scrolling to top

        if ($(this).hasClass('is-open')) {
            $(this).removeClass('is-open');
            $menu.removeClass('is-open');
            $header.removeClass('s-header--mob-menu-is-open');
        } else {
            $(this).addClass('is-open');
            $menu.addClass('is-open');
            $header.addClass('s-header--mob-menu-is-open');
        }
    });

    $submenOpen.on('click', function (e) {
      e.preventDefault(); // Prevents scrolling to top
      
      if (!isMobile()) {
        $(this).parents('.js-has-submenu').toggleClass('is-submenu-open');
      } else {
        $(this).parents('.js-has-submenu').addClass('is-submenu-open');
      }
      });

    $submenuClose.on('click', function (e) {
      e.preventDefault(); // Prevents scrolling to top
      $(this).removeClass('is-submenu-open');
      $(this).parents('.js-has-submenu').removeClass('is-submenu-open');
    });

    $searchToggle.on('click', (e) => {
      e.preventDefault();
      $header.toggleClass('has-open-search');
      $('body').toggleClass('has-open-search');
    });

    $searchOpen.on('click', function (e) {
        $search.addClass('is-open');
        $search.find('input').focus();
    });

    $searchClose.on('click', function (e) {
        $search.removeClass('is-open');
    });


    var emailPlaceholder = $('.form-email-label').html();
    $basedriverNewsletterForm.find('.wpbd-email').attr('placeholder', emailPlaceholder);

    $toggleFAQ.on('click', function (e) {
        e.stopPropagation();
        var activeClassName = 'is-open';

        if ($(this).hasClass(activeClassName)) {
            $(this).removeClass(activeClassName);
            $(this).next().slideUp();
        } else {
            $toggleFAQ.removeClass(activeClassName);
            $toggleFAQ.next().slideUp();

            $(this).addClass(activeClassName);
            $(this).next().slideDown();
        }
    });
});

function isMobile() {
    return window.matchMedia("only screen and (max-width:" + 992 + "px)").matches;
}
