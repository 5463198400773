let $sliders;

$(document).ready(($) => {
  let resizeTimer;
  const sliderSpacing = 24;
  let scrollTimer;
  let isScrolling = false;

  $sliders = $('.js-slider');

  function checkSliderStatus() {
    $sliders.each(function () {
      const $slider = $(this);
      const $sliderItems = $slider.find('.c-slider__items');
      const $sliderNext = $slider.find('.js-slider-next');
      const $sliderPrev = $slider.find('.js-slider-prev');
      const itemContainerWidth = $sliderItems.outerWidth();
      let itemsWidth = 0;

      // Bereken de totale breedte van de items in de slider
      $sliderItems.children().each(function () {
        itemsWidth += $(this).outerWidth(true);
      });

      // Voeg een class toe aan de slider en schakel knoppen in/uit
      if (itemsWidth > itemContainerWidth) {
        $slider.addClass('has-slider');

        const isAtBeginning = $sliderItems.scrollLeft() === 0;
        const endOfSlider = $sliderItems.scrollLeft() + $sliderItems.width();
        const isAtEnd = endOfSlider === $sliderItems[0].scrollWidth;

        $sliderNext.prop('disabled', isAtEnd);
        $sliderPrev.prop('disabled', isAtBeginning);
      } else {
        $slider.removeClass('has-slider');
        $sliderNext.prop('disabled', true);
        $sliderPrev.prop('disabled', true);
      }
    });
  }

  // Voer de check bij het laden van de pagina uit
  checkSliderStatus();

  // Voeg een event listener toe voor het resize evenement
  $(window).on('resize', () => {
    // Wacht kort na het eindigen van het resize evenement voordat je de check uitvoert
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      checkSliderStatus();
    }, 200);
  });

  $sliders.each(function () {
    const $slider = $(this);
    const $sliderNext = $slider.find('.js-slider-next');
    const $sliderPrev = $slider.find('.js-slider-prev');
    const $itemContainer = $slider.find('.c-slider__items');

    $sliderNext.on('click', () => {
      const $sliderItems = $itemContainer.children();
      const currentLeft = $itemContainer.scrollLeft();
      const itemWidth = $sliderItems.outerWidth();
      const newLeft = currentLeft + itemWidth + sliderSpacing;
      setNewPosition(newLeft);
    });

    $sliderPrev.on('click', () => {
      const $sliderItems = $itemContainer.children();
      const currentLeft = $itemContainer.scrollLeft();
      const itemWidth = $sliderItems.outerWidth();
      const newLeft = currentLeft - itemWidth - sliderSpacing;
      setNewPosition(newLeft);
    });

    // Function that adds class is-animating, set new position, and removes class is-animating
    function setNewPosition(newLeft) {
      $itemContainer.addClass('is-animating');
      $itemContainer.animate({ scrollLeft: newLeft }, 300, () => {
        checkButtonStatus();
        $itemContainer.removeClass('is-animating');
      });
    }

    // Function to check the button status
    function checkButtonStatus() {
      const margin = 20; // Adjust this value as needed
      $slider.find('.js-slider-next').prop('disabled', $itemContainer.scrollLeft() + $itemContainer.width() + margin >= $itemContainer[0].scrollWidth);
      $slider.find('.js-slider-next').toggleClass('is-hidden', $itemContainer.scrollLeft() + $itemContainer.width() + margin >= $itemContainer[0].scrollWidth);


      $slider.find('.js-slider-prev').prop('disabled', $itemContainer.scrollLeft() <= margin);
      $slider.find('.js-slider-prev').toggleClass('is-hidden', $itemContainer.scrollLeft() <= margin);
    }

    // Function to handle scroll events
    $itemContainer.on('scroll', () => {
      isScrolling = true;
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(() => {
        if (isScrolling) {
          checkButtonStatus();
        }
      }, 100);
    });
  });
});
