$(document).ready(function($) {
    modalBox();
});

function modalBox() {
    var numPageviews = getCookie('fnl-session-pageviews');
    if (numPageviews === null) numPageviews = 0;
    else numPageviews = parseInt(numPageviews);

    var $modalBox = $("#modalbox");
    if ($modalBox.length) {
        numPageviews++;
        setCookie('fnl-session-pageviews', (numPageviews), 0);

        var hideModalBox = getCookie('fnl-hide-modalbox');
        if (hideModalBox === null) hideModalBox = false;

        var mbConfig = $modalBox.data();


        var showModalBox = null;// = !hideModalBox;

        if (mbConfig.force) {
            showModalBox = true;
            mbConfig.openTimeout = 0;
        } else if (hideModalBox) {
            showModalBox = false;
        } else if (mbConfig.openPageviews > 0) {
            showModalBox = (mbConfig.openPageviews === numPageviews);
        } else {
            showModalBox = true;
        }

        if (showModalBox) {

            window.setTimeout(function () {
                $modalBox.addClass('show');

                //Set placeholder
                var $basedriverForm = $modalBox.find('form');

                $basedriverForm.find('input[type=email]').each(function () {
                    var label = $basedriverForm.find('label[for=' + $(this).attr('id') + ']').text().replace(':', '');
                    $(this).attr('placeholder', label);
                });


                if (mbConfig.autoclose) {
                    $mbProgress = $("#modalbox-progressbar");
                    $mbProgress.css('transitionDuration', mbConfig.autocloseTimeout + 's');
                    $mbProgress.addClass('animate');
                    window.setTimeout(function () {
                        $modalBox.closeModalBox()
                    }, (mbConfig.autocloseTimeout * 1000));
                }

            }, (mbConfig.openTimeout * 1000));


            $(".modalbox-close").click(function () {
                $modalBox.closeModalBox();
            });
        } else {
            $modalBox.remove();
        }
    }
}

$.fn.closeModalBox = function() {

    var $modalBox = $(this);
    var mbConfig = $modalBox.data();

    setCookie('fnl-hide-modalbox',1,mbConfig.cookieLifetime);
    $modalBox.removeClass('show');
    window.setTimeout(function(){
        $modalBox.remove();
    },500);
};

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}
